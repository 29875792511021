import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { TruncateTextPipe, CampaignTypePipe } from "./truncate-text.pipe";

@NgModule({
  declarations: [TruncateTextPipe, CampaignTypePipe],
  imports: [CommonModule],
  exports: [TruncateTextPipe, CampaignTypePipe]
})

export class MainPipe { }